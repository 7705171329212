<template>
    <div>
        <input v-bind:id="inputId"
               v-model="files"
               :required="required ? true : null"
               class="data-input"
               v-bind:name="inputId" />
        <image-upload @changeFiles="updateFiles($event)"
                      :locale="locale"
                      :max-images-count="maxFilesCount"
                      :file-types="fileTypes"
                      upload-action="upload-encrypted.json"
                      :max-size-per-image-kb="maxFileSize"></image-upload>
    </div>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";

export default {
    name: 'IdentityDocumentUpload',
    components: {ImageUpload},
    props: ['inputId', 'locale', 'required', 'fileTypes', 'maxFileSize', 'maxFilesCount'],
    data() {

        console.log(this.maxFilesCount);

        return {
            files: null,
            minFilesCount: 1
        };
    },
    methods: {
        updateFiles(files) {
            if (files.length >= this.minFilesCount) {
                this.files = files.join(',');
            } else {
                this.files = null;
            }
        }
    }
}
</script>

<style scoped>
.data-input {
    display: none;
}

</style>
