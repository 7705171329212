<template>
    <div class="alert alert-danger small mb-2" v-if="showValidationErrorMessage">{{getValidationErrorText()}}</div>
    <div class="alert alert-danger small mb-2" v-if="showUnknownErrorMessage">{{getUnknownErrorText()}}</div>
    <div id="image-upload-area" class="image-upload-area" :class="[dragClass]"
         @click="browse($event)"
         @drop.prevent="drop($event)"
         @dragover.prevent="dragOver"
         @dragleave.prevent="dragLeave">
        <input type="file" id="image-upload-button" class="d-none"
               :accept="acceptedTypes.join(', ') + ';capture=camera'"
               @change="selectFiles($event)" />
        <div id="image-upload-title" class="image-upload-title">
            {{ getInputText() }}
        </div>
        <div class="image-upload-preview" id="image-upload-preview">
            <div v-for="(image, key) in uploadedFiles" :key="key" class="image-upload-preview-image">
                <img v-if="!image.type.startsWith('application')"
                     class="image-upload-preview-image-img"
                     :src="image.url">
                <i v-if="image.type.startsWith('application')" class="fa fa-file-pdf"></i>
                <div class="image-upload-image-delete" @click="removeFile(image)">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div class="image-upload-preview-image" v-if="isLoading">
                <i class="fa fa-spinner fa-spin fa-2x"></i>
            </div>
        </div>
        <input type="hidden" v-model="fileReferences" name="file_references" />
    </div>
</template>

<script>
export default {
    name: 'ImageUpload',
    data() {
        if(this.pictures) {
            for (let i = 0; i < this.pictures.length; i++) {
                let picture = this.pictures[i];
                let fileName = 'ORIGINAL_' + picture.name;
                let imageUrl = picture.path + fileName;

                fetch(imageUrl)
                    .then(response => {
                        response.blob().then((blob) => {
                            this.handleFile(new File([blob], fileName, {type: response.headers.get('content-type')}));
                        });
                    });
            }
        }

        return {
            isLoading: false,
            dragClass: '',
            uploadedFiles: [],
            fileReferences: [], // Array to store references
            validationMaxSizePerImageKb: 15000,
            validationMaxImagesCount: 4,
            acceptedTypes: ['image/png', 'image/gif', 'image/jpeg'],
            currentFilesCount: 0,
            showValidationErrorMessage: false,
            showUnknownErrorMessage: false,
            translations: {
                'de': {
                    title: 'Ziehen und Ablegen oder Durchsuchen ($TYPES, Max. $MAX_SIZE MB)',
                    validationError: 'Als Upload werden ausschließlich Dateien des Typs $TYPES bis zu einer maximalen Größe von $MAX_SIZE MB akzeptiert.',
                    backendError: 'Beim Hochladen der Datei ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support unter info@premesec.ch, falls das Problem bestehen bleibt.',
                },
                'en': {
                    title: 'Drag & Drop or Browse ($TYPES, max. $MAX_SIZE MB)',
                    validationError: 'Only files of type $TYPES up to a maximum size of $MAX_SIZE MB are accepted as uploads.',
                    backendError: 'An unknown error occurred while uploading the file. Please try again or contact support at info@premesec.ch if the problem persists.',
                },
                'it': {
                    title: 'Trascinare e rilasciare o sfogliare ($TYPES, massimo $MAX_SIZE MB)',
                    validationError: 'Solo i file di tipo $TYPES fino a una dimensione massima di $MAX_SIZE MB sono accettati per il caricamento.',
                    backendError: 'Si è verificato un errore sconosciuto durante il caricamento del file. Riprovare o contattare l\'assistenza all\'indirizzo info@premesec.ch se il problema persiste.',
                },
                'fr': {
                    title: 'Glisser-déposer ou parcourir ($TYPES, $MAX_SIZE Mo maximum)',
                    validationError: 'Seuls les fichiers de type $TYPES d\'une taille maximale de $MAX_SIZE MB sont acceptés en téléchargement.',
                    backendError: 'Une erreur inconnue s\'est produite lors du téléchargement du fichier. Veuillez réessayer ou contacter le support à l\'adresse info@premesec.ch si le problème persiste.',
                }
            }
        };
    },
    props: ['maxSizePerImageKb', 'maxImagesCount', 'minImagesCount', 'locale', 'fileTypes', 'uploadAction', 'pictures'],
    emits: ['changeFiles'],
    mounted() {
        if (this.fileTypes && typeof this.fileTypes === 'string') {
            this.acceptedTypes = this.fileTypes.split(',');
        }
        if (this.maxSizePerImageKb) {
            this.validationMaxSizePerImageKb = this.maxSizePerImageKb;
        }
        if (this.maxImagesCount) {
            this.validationMaxImagesCount = this.maxImagesCount;
        }
        if (this.minImagesCount) {
            this.validationMinImagesCount = this.minImagesCount;
        }
    },
    methods: {
        dragOver() {
            this.dragClass = 'image-upload-drag-over';
        },
        dragLeave() {
            this.dragClass = '';
        },
        browse(e) {
            if (!this.isLoading && this.validateFilesCount()
                && (e.target.id === 'image-upload-area'
                    || e.target.id === 'image-upload-preview'
                    || e.target.id === 'image-upload-title')) {
                document.getElementById('image-upload-button').click();
            }
        },
        selectFiles(e) {
            const files = e.target.files || [];
            for (let i = 0; i < files.length; i++) {
                this.handleFile(files[i]);
            }
            document.getElementById('image-upload-button').value = '';
        },
        drop(e) {
            if (!this.isLoading) {
                this.dragClass = '';
                const files = e.dataTransfer.files || [];
                for (let i = 0; i < files.length; i++) {
                    this.handleFile(files[i]);
                }
            }
        },
        async handleFile(file) {
            if (!this.validateFile(file)) {
                this.showValidationError();
                return;
            }

            this.isLoading = true;

            try {
                const formData = new FormData();
                formData.append('file', file);

                // Send file via AJAX
                const response = await fetch('/uploads/' + this.uploadAction, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const parsedRes = await response.json();

                    const fileReference = parsedRes.data.reference;

                    // Store reference in hidden input
                    this.fileReferences.push(fileReference);
                    this.currentFilesCount++;
                    this.hideErrorMessages();

                    // Create a URL for the uploaded file preview
                    const imageUrl = URL.createObjectURL(file);
                    this.uploadedFiles.push({ url: imageUrl, reference: fileReference, type: file.type });
                } else {
                    this.showUnknownError();
                }
            } finally {
                this.emitChange();
                this.isLoading = false;
            }
        },
        showValidationError() {
            this.showValidationErrorMessage = true;
        },
        showUnknownError() {
            this.showUnknownErrorMessage = true;
        },
        hideErrorMessages() {
            this.showValidationErrorMessage = false;
            this.showUnknownErrorMessage = false;
        },
        validateFile(file) {
            if (!this.validateFileType(file)) {
                return false;
            } else if (!this.validateFileSize(file)) {
                return false;
            } else if (!this.validateFilesCount()) {
                return false;
            } else if (this.isFileAlreadyUploaded(file)) {
                return false;
            }
            return true;
        },
        validateFileType(file) {
            return this.acceptedTypes.includes(file.type);
        },
        validateFileSize(file) {
            return file.size <= (this.validationMaxSizePerImageKb * 1024);
        },
        validateFilesCount() {
            return this.currentFilesCount < this.validationMaxImagesCount;
        },
        isFileAlreadyUploaded(file) {
            return this.uploadedFiles.some(uploadedFile => uploadedFile.name === file.name && uploadedFile.size === file.size);
        },
        removeFile(file) {
            const index = this.uploadedFiles.indexOf(file);
            if (index > -1) {
                this.uploadedFiles.splice(index, 1);
                this.fileReferences.splice(index, 1);
                this.currentFilesCount--;
                this.emitChange();
            }
        },
        emitChange() {
            this.$emit('changeFiles', this.fileReferences);
        },
        getInputText() {
            return this.getLocalizedDisplayMessage('title');
        },
        getValidationErrorText() {
            return this.getLocalizedDisplayMessage('validationError');
        },
        getUnknownErrorText() {
            return this.getLocalizedDisplayMessage('backendError');
        },
        getLocalizedDisplayMessage(messageKey) {
            let message = this.translations[this.locale][messageKey];
            message = message.replace('$TYPES', this.getAcceptedFileTypesInfo());
            message = message.replace('$MAX_SIZE', this.validationMaxSizePerImageKb / 1000);
            return message;
        },
        getAcceptedFileTypesInfo() {
            return this.acceptedTypes.map(type => type.split('/').pop().toUpperCase()).join(', ');
        }
    }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.image-upload-area {
    border: #495057 dashed thin;
    color: #495057;
    width: 100%;
    min-height: 45px;
    padding: 10px;
}

.image-upload-area:hover {
    cursor: pointer;
    border-color: black;
    color: black;
}

.image-upload-title {
    width: 100%;
    text-align: center;
}

.image-upload-image {
    width: 100px;
    height: 75px;
}

.image-upload-drag-over {
    border-color: gold;
    color: gold;
}

.image-upload-preview {
    display: flex;
    flex-wrap: wrap;
}

.image-upload-preview-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border: #495057 thin solid;
    width: 104px;
    height: 79px;
    margin: 5px;
}

.image-upload-preview-image:hover {
    border-color: black;
}

.image-upload-preview-image-img {
    max-width: 100px;
    max-height: 75px
}

.image-upload-image-delete {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 75px;
    font-size: 30px;
}

.image-upload-image-delete svg {
    display: none;
}

.image-upload-image-delete:hover svg {
    display: block;
}

.image-upload-image-delete:hover {
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.5);
}
</style>
